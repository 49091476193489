<template>
  <div class="login" id="login">
    <div class="login-card">
      <h3>登入</h3>
      <div class="my-4">
        <label class="mr-2">帳號</label>
        <input class="ml-2" type="text" name="userName" v-model="account" />
      </div>
      <div class="my-4">
        <label class="mr-2">密碼</label>
        <input
          class="ml-2"
          type="password"
          name="password"
          v-model="password"
        />
      </div>
      <div>
        <button @click="validate()">登入</button>
      </div>
      <b-modal id="alert" size="sm" ok-title="確定" ok-only v-model="modalOpen"
        ><b-icon icon="exclamation-triangle" class="mr-2"></b-icon>{{ alertMsg
        }}<b-icon icon="exclamation-triangle" class="ml-2"></b-icon>
        <template #modal-footer>
          <div class="w-100 text-center">
            <b-button variant="dark" size="sm" @click="modalOpen = false">
              確定
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { logIn } from "@/api/index.js";
import { mapMutations } from "vuex";

export default {
  name: "LogIn",
  data() {
    return {
      account: "",
      password: "",
      alertMsg: "",
      modalOpen: false,
    };
  },
  mounted() {},
  methods: {
    ...mapMutations(["setToken"]),
    validate() {
      if (this.account !== "" && this.password !== "") {
        this.goLogIn();
      } else {
        if (this.account === "" && this.password === "") {
          this.alertMsg = "請輸入帳號和密碼";
        } else if (this.account === "") {
          this.alertMsg = "請輸入帳號";
        } else if (this.password === "") {
          this.alertMsg = "請輸入密碼";
        }
        this.modalOpen = true;
      }
    },
    goLogIn() {
      logIn({ account: this.account, password: this.password })
        .then((res) => {
          if (res.data.status === "success") {
            this.setToken(res.data.access_token);
            this.$cookie.set("token", res.data.access_token);
            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6b8985;
}
.login-card {
  background: #fff;
  box-shadow: 0px 0px 17px 6px #d1cfcf;
  padding: 40px 20px;
  border-radius: 15px;
  width: 400px;
  margin: auto;
  h3 {
    font-weight: bold;
  }
  button {
    border: #6b8985 1px solid;
    background: #6b8985;
    color: #fff;
    border-radius: 15px;
    padding: 8px 12px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
  }
}
.modal-body {
  text-align: center;
}
.modal-header,
.modal-footer {
  border: none !important;
}
</style>
