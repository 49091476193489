import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGeoLocation from "vue-browser-geolocation";
import * as VueGoogleMaps from "vue2-google-maps";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueResource from "vue-resource";
import VueCookie from "vue-cookie";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "./store";
Vue.config.productionTip = false;
Vue.use(VueGeoLocation);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookie);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyABE6ToN1HJROXx2nnMM9MS25QGdtUqsik",
    libraries: ["geometry", "places"],
    region: "TW",
    lanuage: "zh-TW",
  },
  installComponents: true,
});
Vue.use(VueResource);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
