<template>
  <div class="table" id="table">
    <div class="basic-info">
      <div class="table-date">日期:{{ dispatchData.formattedDate }}</div>
      <b-row class="note">
        <b-col
          >休假:
          <span v-for="item in leaveData" :key="item.id">
            {{ item.name }}
          </span></b-col
        >
        <b-col v-html="dispatchData.note"> </b-col>
      </b-row>
      <b-row class="driver-list">
        <b-col
          :cols="2"
          v-for="(driver, index) in driverData"
          :key="driver.id"
          class="driver-item"
          :class="{ isLastRow: isLastRow(index) }"
        >
          <div>{{ driver.sn }}</div>
          <div class="userName">{{ driver.user_name }}</div>
        </b-col>
      </b-row>
    </div>
    <div class="page-break"></div>
    <b-table :fields="fields" :items="dataTableFormatted">
      <template #cell(startCarUserList)="data">
        <p v-for="(item, index) in data.value" :key="index">
          <span class="mr-1">{{ item.sn }}</span
          ><span class="ml-1">{{ item.user_name }}</span>
        </p>
      </template>
      <template #cell(endCarUserVehicleList)="data">
        <b-row>
          <b-col cols="6" v-for="(item, index) in data.value" :key="index">
            <span class="mr-1">{{ item.sn }}</span
            ><span class="ml-1">{{ item.user_name }}</span>
          </b-col>
        </b-row>
      </template>
      <template #cell(endCarUserList)="data">
        <p v-for="(item, index) in data.value" :key="index">
          <span class="mr-1">{{ item.sn }}</span
          ><span class="ml-1">{{ item.user_name }}</span>
        </p>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  getShiftDayExtra,
  getShiftDay,
  getShiftNight,
  getDriverDayExtra,
  getDriverDay,
  getDriverNight,
  getDispatch,
  getLeave,
} from "@/api/index.js";
export default {
  name: "Table",
  data() {
    return {
      startLocaleString: "",
      endLocaleString: "",
      leaveData: [],
      dispatchData: [],
      driverData: [],
      dataTableFormatted: [],
      dataTable: [],
      fields: [
        { key: "shiftType", label: "班別", thStyle: { width: "8%" } },
        { key: "startArea", label: "施工區域", thStyle: { width: "8%" } },
        { key: "type", label: "施工内容", thStyle: { width: "8%" } },
        {
          key: "startCarUserList",
          label: "施工機具",
          thStyle: { width: "15%" },
        },
        {
          key: "endCarUserVehicleList",
          label: "施工車輛",
          thStyle: { width: "30%" },
        },
        { key: "endArea", label: "土尾區域", thStyle: { width: "10%" } },
        { key: "endCarUserList", label: "土尾機具", thStyle: { width: "20%" } },
      ],
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    setInterval(() => {
      if (this.$route.name === "Table") {
        this.dataTable.splice(0, this.dataTable.length);
        this.getData();
      }
    }, 10000);
  },
  computed: {
    shift() {
      return this.$route.params.shift;
    },
  },
  methods: {
    formattedDate(date) {
      let startTime = new Date(date);
      console.log({
        start: startTime.toISOString(),
        end: new Date(date.substring(0, 10) + " 23:59").toISOString(),
      });
      getLeave({
        start: startTime.toISOString(),
        end: new Date(date.substring(0, 10) + " 23:59").toISOString(),
      })
        .then((res) => {
          this.leaveData = res.data;
        })
        .catch((error) => {
          console.log("error", error.response);
          if (error.response && error.response.status === 401) {
            this.$bvModal.show("alert");
          }
        });

      const d = new Date(date.substring(0, 10));
      let weekDay = d.getDay();
      const year = parseInt(date.substring(0, 4)) - 1911;
      const month =
        date.at(5) === "0" ? date.substring(6, 7) : date.substring(5, 7);
      const day =
        date.at(8) === "0" ? date.substring(9, 10) : date.substring(8, 10);

      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "號 ( " +
        this.turnEnToZh(weekDay) +
        " )"
      );
    },
    turnEnToZh(num) {
      switch (num) {
        case 0:
          return "日";
        case 1:
          return "一";
        case 2:
          return "二";
        case 3:
          return "三";
        case 4:
          return "四";
        case 5:
          return "五";
        case 6:
          return "六";
      }
    },
    // 取得班表
    async getData() {
      getDispatch(this.$route.params.system)
        .then((res) => {
          this.dispatchData = res.data.rows[0];
          var getYear = new Date(this.dispatchData.date).toLocaleString(
            "zh-tw",
            { year: "numeric" }
          );
          var getMonth = new Date(this.dispatchData.date).toLocaleString(
            "zh-tw",
            { month: "2-digit" }
          );
          var getDay = new Date(this.dispatchData.date).toLocaleString(
            "zh-tw",
            { day: "2-digit" }
          );
          this.dispatchData.formattedDate = this.formattedDate(
            getYear.slice(0, -1) +
              "/" +
              getMonth.slice(0, -1) +
              "/" +
              getDay.slice(0, -1)
          );
          this.dispatchData.note = this.dispatchData.note.replace(
            /(\r\n)|(\n)/g,
            "<br>"
          );
        })
        .catch((error) => {
          console.log("error", error.response);
          if (error.response && error.response.status === 401) {
            this.$bvModal.show("alert");
          }
        });
      switch (this.shift) {
        case "dayExtra":
          await getShiftDayExtra(this.$route.params.system)
            .then((res) => {
              const result = res.data.rows;
              result.forEach((e) => {
                e.shiftType = "早班加班";
              });
              this.dataTable = this.dataTable.concat(result);
            })
            .catch((error) => {
              console.log("error", error.response);
              if (error.response && error.response.status === 401) {
                this.$bvModal.show("alert");
              }
            });
          getDriverDayExtra(this.$route.params.system)
            .then((res) => {
              this.driverData = res.data.rows;
            })
            .catch((error) => {
              console.log("error", error.response);
              if (error.response && error.response.status === 401) {
                this.$bvModal.show("alert");
              }
            });
          break;
        case "day":
          await getShiftDay(this.$route.params.system)
            .then((res) => {
              const result = res.data.rows;
              result.forEach((e) => {
                e.shiftType = "早班";
              });
              this.dataTable = this.dataTable.concat(result);
            })
            .catch((error) => {
              console.log(error);
            });

          getDriverDay(this.$route.params.system)
            .then((res) => {
              this.driverData = res.data.rows;
            })
            .catch((error) => {
              console.log("error", error.response);
              if (error.response && error.response.status === 401) {
                this.$bvModal.show("alert");
              }
            });
          break;
        case "night":
          await getShiftNight(this.$route.params.system)
            .then((res) => {
              const result = res.data.rows;
              result.forEach((e) => {
                e.shiftType = "晚班";
              });
              this.dataTable = this.dataTable.concat(result);
            })
            .catch((error) => {
              console.log(error);
            });
          getDriverNight(this.$route.params.system)
            .then((res) => {
              this.driverData = res.data.rows;
            })
            .catch((error) => {
              console.log("error", error.response);
              if (error.response && error.response.status === 401) {
                this.$bvModal.show("alert");
              }
            });
          break;
      }
      await this.formatData();
    },
    formatData() {
      this.dataTableFormatted.splice(0, this.dataTableFormatted.length);
      this.dataTable.forEach((e) => {
        this.dataTableFormatted.push({
          shiftType: e.shiftType,
          startArea: e.start_area,
          type: e.type,
          endArea: e.end_area,
          startCarUserList: e.start_car_user_list,
          endCarUserVehicleList: e.end_car_user_vehicle_list,
          endCarUserList: e.end_car_user_list,
        });
      });
    },
    isLastRow(index) {
      if (index >= this.driverData.length - (this.driverData.length % 6))
        return true;
    },
  },
};
</script>
<style lang="scss">
.basic-info {
  margin: 0 40px 20px 40px;
  .table-date {
    text-align: left;
    font-size: 20px;
  }
  .note {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .driver-list {
    border: #dee2e6 1px solid;
    .driver-item {
      display: flex;
      padding: 10px;
      &:not(.isLastRow) {
        border-bottom: #dee2e6 1px solid;
      }
      &:not(:nth-child(6n)) {
        border-right: #dee2e6 1px solid;
      }
      .userName {
        margin-left: 5px;
      }
    }
  }
}
thead {
  th {
    padding: 0.2rem;
    border-left: 1px solid #dee2e6;
  }
}
tbody {
  tr {
    &:last-child:not(:first-child) {
      border-bottom: 1px solid #dee2e6;
    }
    td {
      padding: 0.2rem !important;
      border-left: 1px solid #dee2e6;
    }
  }
}
p {
  margin-bottom: 0 !important;
}
@media print {
  .page-break {
    page-break-before: always;
  } /* page-break-after works, as well */
}
</style>
