<template>
  <div class="map" id="map">
    <GmapMap
      :center="coordinate"
      :zoom="16"
      :style="{ width: '100vw', height: '100vh' }"
      ref="mapRef"
      id="map"
      :options="{
        mapTypeControl: false,
        streetViewControl: false,
      }"
      mapTypeId="satellite"
    >
      <div v-if="isAllView">
        <GmapMarker
          :key="m.name"
          v-for="m in eachAreaMarker"
          :position="{
            lat: m.lat,
            lng: m.lng,
          }"
          :label="{
            text: m.name,
            fontSize: '20px',
            className: 'each-position',
          }"
          :clickable="false"
          :draggable="false"
          :icon="{
            url: require('@/static/img/transparent_bg.png'),
          }"
        >
        </GmapMarker>
      </div>
      <GmapMarker
        :key="m.sn"
        v-for="m in filterLocations"
        :position="{
          lat: parseFloat(m.SoilLocations[0].latitude),
          lng: parseFloat(m.SoilLocations[0].longitude),
        }"
        :label="{
          text: m.sn,
          fontSize: '14px',
          color:
            m.model === '絞接式卡車'
              ? 'purple'
              : m.model === '挖土機'
              ? 'red'
              : 'black',
          className: 'marker-position',
        }"
        @click="getInfo(m)"
        :clickable="true"
        :draggable="false"
        :icon="{
          url:
            m.model === '絞接式卡車'
              ? require('@/static/img/final_cat_car_' +
                  m.SoilLocations[0].direction +
                  '.png')
              : m.model === '挖土機'
              ? require('@/static/img/final_volvo_' +
                  m.SoilLocations[0].direction +
                  '.png')
              : require('@/static/img/final_other_' +
                  m.SoilLocations[0].direction +
                  '.png'),
        }"
      >
      </GmapMarker>
      <GmapPolygon
        v-for="(path, index) in pathData"
        :key="index"
        :paths="path"
        :options="{
          strokeColor: '#336699',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#336699',
          fillOpacity: 0.35,
        }"
      />
    </GmapMap>
    <div id="filter-btn-group">
      <button
        id="car-btn"
        class="filter-btn"
        :class="{ active: isShowCar }"
        @click="doFilterCar(0)"
      ></button>
      <button
        id="cat-car-btn"
        class="filter-btn"
        :class="{ active: isShowCatCar }"
        @click="doFilterCar(1)"
      ></button>
      <button
        id="other-btn"
        class="filter-btn"
        :class="{ active: isOtherCar }"
        @click="doFilterCar(2)"
      ></button>
      <button
        id="each-btn"
        class="filter-area-btn"
        :class="{ active: isEachOpen }"
        @click="doFilterArea(0)"
      ></button>
      <button
        id="all-btn"
        class="filter-area-btn"
        :class="{ active: isAllView }"
        @click="doFilterArea(2)"
      ></button>
      <button
        id="table-btn"
        class="filter-area-btn"
        :class="{ active: isTableOpen }"
        @click="toggleTableBtn('-1')"
      ></button>
    </div>
    <div v-show="isTableOpen" id="table-options" class="filter-options">
      <div class="each-options-block">
        <p class="each-option" @click="toggleTableBtn('dayExtra')">早班加班</p>
        <p class="each-option" @click="toggleTableBtn('day')">早班</p>
        <p class="each-option" @click="toggleTableBtn('night')">晚班</p>
      </div>
    </div>
    <div v-show="isEachOpen" id="each-options" class="filter-options">
      <div class="each-options-block">
        <p
          v-for="each in eachArea"
          :key="each.id"
          class="each-option"
          @click="goEachArea(each.id)"
        >
          {{ each.name }}
        </p>
      </div>
    </div>
    <div v-if="clickedCar !== null" id="car-info-window">
      <div style="position: absolute; top: 0px; right: 1px">
        <button @click="clickedCar = null" id="close-btn">
          <b-icon icon="x" style="width: 25px; height: 25px"></b-icon>
        </button>
      </div>
      <div class="img-block">
        <img
          :src="
            clickedCar.model === '絞接式卡車'
              ? require('@/static/img/final_cat_car_side.png')
              : clickedCar.model === '挖土機'
              ? require('@/static/img/final_volvo_side.png')
              : require('@/static/img/final_other_side.png')
          "
        />
      </div>
      <div class="text-block">
        <p>車型:{{ clickedCar.model }}</p>
        <p>車牌號碼:{{ clickedCar.sn }}</p>
        <p>廠牌:{{ clickedCar.brand }}</p>
        <p>車速:{{ clickedCar.SoilLocations[0].speed }}Km/h</p>
        <p>司機:{{ clickedCar.SoilLocations[0].user_name }}</p>
        <p>土頭目標:{{ clickedCar.SoilLocations[0].start_area }}</p>
        <p>土尾目標:{{ clickedCar.SoilLocations[0].end_area }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getArea } from "@/api/index.js";
import { getLocation } from "@/api/index.js";
export default {
  name: "Map",
  data() {
    return {
      isTableOpen: false,
      eachAreaMarker: [],
      pathData: [],
      allArea: [],
      eachArea: [],
      carActiveArray: [true, true, true],
      areaActiveArray: [false, false, true],
      clickedCar: null,
      openedMarkerID: null,
      service: null,
      placeIdForWindow: "",
      map: null,
      coordinate: {
        lat: 24.99429702758789,
        lng: 121.52513885498047,
      },
      allCarLocation: [],
      filterLocations: [],
      fullViewCenter: {},
      fullViewBound: {},
      isAllView: true,
      dataIsLoad: false,
    };
  },
  watch: {
    dataIsLoad(newVal) {
      if (newVal) {
        this.getLocationFromApi();
      }
    },
  },
  created() {
    // 全區
    this.dataIsLoad = false;
    getArea({ area: 0 })
      .then((res) => {
        this.allArea = res.data.rows[0];
        this.setCanterAndBound(this.allArea.location, "all");
      })
      .catch((error) => {
        console.log(error);
      });
    // 分區地圖
    getArea({ area: 1 })
      .then((res) => {
        this.eachArea = res.data.rows;
        this.setEachAreaMarker(res.data.rows);
        this.setPolygon();
      })
      .catch((error) => {
        console.log(error);
      });
    this.getLocationFromApi();
  },
  mounted() {
    document.getElementById("each-options").style.left =
      (document.getElementById("each-btn").offsetLeft + 5).toString() + "px";
    document.getElementById("table-options").style.left =
      (document.getElementById("table-btn").offsetLeft + 5).toString() + "px";
  },
  computed: {
    isShowCar() {
      return this.carActiveArray[0];
    },
    isShowCatCar() {
      return this.carActiveArray[1];
    },
    isOtherCar() {
      return this.carActiveArray[2];
    },
    isEachOpen() {
      return this.areaActiveArray[0];
    },
  },
  methods: {
    getLocationFromApi() {
      this.dataIsLoad = false;
      getLocation({})
        .then((res) => {
          this.allCarLocation.splice(0, this.allCarLocation.length);
          let rowsData = res.data.rows;
          rowsData.forEach((element) => {
            if (element.SoilLocations.length !== 0) {
              this.allCarLocation.push(element);
            }
          });
          this.allCarLocation.forEach((element) => {
            element.SoilLocations[0].direction = this.formatDirection(
              element.SoilLocations[0].direction
            );
          });
          this.doFilterCar(-1);
          this.dataIsLoad = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTableBtn(shift) {
      this.isTableOpen = !this.isTableOpen;
      if (shift !== "-1") {
        this.$router.push("/table/" + shift + "/1");
      }
    },
    formatDirection(dir) {
      switch (Math.ceil(dir / 45)) {
        case 1:
          return 45;
        case 2:
          return 90;
        case 3:
          return 135;
        case 4:
          return 180;
        case 5:
          return 225;
        case 6:
          return 270;
        case 7:
          return 315;
        case 8:
          return 0;
        default:
          return 0;
      }
    },
    setEachAreaMarker(data) {
      data.forEach((eachArea) => {
        let allLat = [];
        let allLng = [];
        eachArea.location.forEach((loc) => {
          allLat.push(loc[0]);
          allLng.push(loc[1]);
        });
        const minLat = Math.min(...allLat);
        const maxLat = Math.max(...allLat);
        const minLng = Math.min(...allLng);
        const maxLng = Math.max(...allLng);
        this.eachAreaMarker.push({
          name: eachArea.name,
          lat: (minLat + maxLat) / 2,
          lng: (minLng + maxLng) / 2,
        });
      });
    },
    setCanterAndBound(data, type) {
      let allLat = [];
      let allLng = [];
      data.forEach((e) => {
        allLat.push(e[0]);
        allLng.push(e[1]);
      });
      const minLat = Math.min(...allLat);
      const maxLat = Math.max(...allLat);
      const minLng = Math.min(...allLng);
      const maxLng = Math.max(...allLng);
      if (type === "all") {
        this.isAllView = true;
        this.fullViewCenter = {
          lat: (minLat + maxLat) / 2,
          lng: (minLng + maxLng) / 2,
        };
        this.fullViewBound = {
          north: maxLat,
          south: minLat,
          east: maxLng,
          west: minLng,
        };
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.setCenter({
            lat: (minLat + maxLat) / 2,
            lng: (minLng + maxLng) / 2,
          });

          map.setZoom(15);
        });
      } else {
        this.isAllView = false;
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.setCenter({
            lat: (minLat + maxLat) / 2,
            lng: (minLng + maxLng) / 2,
          });
          map.setZoom(17);
        });
      }
    },
    setPolygon() {
      this.eachArea.forEach((area) => {
        let allLoc = [];
        area.location.forEach((e) => {
          allLoc.push({ lat: e[0], lng: e[1] });
        });
        this.pathData.push(allLoc);
      });
    },
    goEachArea(ID) {
      this.areaActiveArray.splice(0, 1, false);
      this.setCanterAndBound(
        this.eachArea.find((e) => e.id === ID).location,
        "each"
      );
    },
    doFilterCar(index) {
      if (index !== -1) {
        if (!this.carActiveArray[index]) {
          this.carActiveArray.splice(index, 1, true);
        } else {
          this.carActiveArray.splice(index, 1, false);
        }
      }
      const carLocations = this.allCarLocation.filter(
        (e) => e.model === "絞接式卡車"
      );
      const catCarLocations = this.allCarLocation.filter(
        (e) => e.model === "挖土機"
      );
      const otherCarLocations = this.allCarLocation.filter(
        (e) => e.model === "其他"
      );
      const allByFilter = [carLocations, catCarLocations, otherCarLocations];
      this.filterLocations.splice(0, this.filterLocations.length);

      this.carActiveArray.forEach((e, index) => {
        if (e) {
          this.filterLocations = this.filterLocations.concat(
            allByFilter[index]
          );
        }
      });
    },
    doFilterArea(index) {
      // 2:全區
      const isOpened = this.areaActiveArray[index];
      this.areaActiveArray = [false, false, false];
      if (!isOpened) {
        this.areaActiveArray.splice(index, 1, true);
      } else {
        this.areaActiveArray.splice(index, 1, false);
      }
      if (index === 2) {
        this.setCanterAndBound(this.allArea.location, "all");
      }
    },
    getInfo(car) {
      this.clickedCar = car;
    },
  },
};
</script>
<style>
#car-info-window {
  position: absolute;
  top: 50px;
  right: 50px;
  background: #e3e3e1;
  width: 240px;
  padding: 15px 15px 10px 15px;
}
.img-block {
  margin: 10px 0;
  background: #fff;
  padding: 10px 20px;
  text-align: center;
}
.text-block {
  margin: 10px 0;
  background: #fff;
  padding: 10px 20px;
  text-align: left;
}
#close-btn {
  border: none;
  background: transparent;
}
#close-btn:focus {
  outline: none !important;
}
#filter-btn-group {
  position: absolute;
  top: 10px;
  left: 5px;
  display: flex;
  justify-content: space-between;
}
.filter-btn,
.filter-area-btn {
  width: 150px;
  height: 35px;
  border: none;
  margin: 0 15px;
}
.filter-btn:focus,
.filter-area-btn:focus {
  outline: none !important;
}
#car-btn {
  background: center / contain no-repeat url("../static/img/car.png");
}
#car-btn.active {
  background: center / contain no-repeat url("../static/img/car_click.png");
}
#cat-car-btn {
  background: center / contain no-repeat url("../static/img/cat_car.png");
}
#cat-car-btn.active {
  background: center / contain no-repeat url("../static/img/cat_car_click.png");
}
#other-btn {
  background: center / contain no-repeat url("../static/img/other_car.png");
}
#other-btn.active {
  background: center / contain no-repeat
    url("../static/img/other_car_click.png");
}
#each-btn {
  background: center / contain no-repeat url("../static/img/each_area.png");
}
#each-btn.active {
  background: center / contain no-repeat
    url("../static/img/each_area_click.png");
}
#all-btn {
  background: center / contain no-repeat url("../static/img/all_area.png");
}
#all-btn.active {
  background: center / contain no-repeat url("../static/img/all_area_click.png");
}
#table-btn {
  background: center / contain no-repeat url("../static/img/table.png");
}
#table-btn.active {
  background: center / contain no-repeat url("../static/img/table_click.png");
}
.filter-options {
  position: absolute;
  background: #e3e3e1;
  width: 220px;
  padding: 5px 10px;
  top: 50px;
  color: #6b8985;
  cursor: pointer;
}
.each-options-block {
  background: #fff;
}
.each-option {
  padding: 10px;
  font-weight: bold;
  font-size: 22px;
  border-bottom: 1px solid #e3e3e1;
  margin: 0 10px;
}
/* width */
::-webkit-scrollbar {
  width: 25px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #6b8985;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
  border: 5px solid #6b8985;
}

.marker-position {
  bottom: -60%;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  text-shadow: 0 0 15px rgba(255, 255, 255), 0 0 10px rgba(255, 255, 255);
}

.each-position {
  position: relative;
  bottom: -50%;
}
</style>
