<template>
  <div id="app">
    <router-view />
    <b-modal id="alert" size="sm" ok-title="確定" ok-only @hidden="closeAlert()"
      ><b-icon icon="exclamation-triangle" class="mr-2"></b-icon
      >憑證以過期請重新登入<b-icon
        icon="exclamation-triangle"
        class="ml-2"
      ></b-icon>
      <template #modal-footer>
        <div class="w-100 text-center">
          <b-button variant="dark" size="sm" @click="closeAlert()">
            確定
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
// @ is an alias to /src

import { refreshToken } from "@/api/index.js";
export default {
  name: "App",
  data() {
    return {};
  },
  watch: {
    "$store.state.token": function () {
      // 一分鐘更新一次token
      setInterval(this.getToken, 60000);
    },
  },
  created() {
    if (this.$cookie.get("auth_token_default")) {
      this.$cookie.set("token", this.$cookie.get("auth_token_default"));

      refreshToken()
        .then((res) => {
          if (res.data.status === "success") {
            if (this.$route.name === "LogIn") {
              this.$router.push("/");
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$bvModal.show("alert");
          }
        });
    }
  },
  mounted() {},
  methods: {
    closeAlert() {
      this.$bvModal.hide("alert");
      if (this.$route.name !== "LogIn") {
        this.$router.push({ path: "/login" });
      }
    },
    // 更新token
    getToken() {
      refreshToken()
        .then((res) => {
          if (res.data.status === "success") {
            this.$cookie.set("token", res.data.access_token);
            this.getData();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$bvModal.show("alert");
          }
        });
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
