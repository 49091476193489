import { get } from "./http";
import { post } from "./http";
import { getByToken } from "./http";

export const getLocation = () => get("/location/soil");
export const getArea = (params) => get("/location/area", params);
export const logIn = (params) => post("/auth/login", params);
export const refreshToken = (params) => getByToken("/auth/refresh", params);
export const getShiftDayExtra = (params) =>
  getByToken("/soil/dispatch/detail/" + params + "/0");
export const getShiftDay = (params) =>
  getByToken("/soil/dispatch/detail/" + params + "/1");
export const getShiftNight = (params) =>
  getByToken("/soil/dispatch/detail/" + params + "/2");
export const getDispatch = (params) =>
  getByToken("/soil/dispatch?id=" + params);
export const getDriverDayExtra = (params) =>
  getByToken("/soil/car/" + params + "/0");
export const getDriverDay = (params) =>
  getByToken("/soil/car/" + params + "/1");
export const getDriverNight = (params) =>
  getByToken("/soil/car/" + params + "/2");
export const getLeave = (params) => getByToken("/leave", params);
